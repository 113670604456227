<template>
  <div>
    <v-img
      src="../assets/web/background.jpg"
      height="100vh"
      position="center bottom"
    >
      <!-- <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="black"
            :width="7"
            :size="70"
          ></v-progress-circular>
        </v-row>
      </template> -->

      <template v-slot:default>
        
        <div
          class="text-language"
        >
          <div style="padding: 8px">
            <router-link :to="{name: 'Hospitium', params: {lang: 'es'}}" class="router-link">ESPAÑOL</router-link>
          </div>
          <div style="margin-top: 25px; padding: 8px">
            <router-link :to="{name: 'Hospitium', params: {lang: 'en'}}" class="router-link">ENGLISH</router-link>
          </div>
        </div>

      </template>

    </v-img>
  </div>
</template>

<script>

export default {
  name: 'home',
  data() {
    return {
    }
  }
}
</script>

<style scoped>
  .text-language {
    position: absolute;
    left: 30vw;
    top: 35vh;
    width: 40vw;
    text-align: center;
    font-size: 18px;
  }
  .text-language div {
    cursor: pointer;
  }
  .text-language div:hover {
    background: black;
    color: white;
  }

  .router-link {
    text-decoration: none;
    color: inherit;
  }
  .router-link:visited {
    color: inherit;
  }
</style>